import React, { PureComponent, Fragment } from 'react';
import { Link } from 'dva/router';
import { connect } from 'dva';
import { Row, Col, Select, Tooltip, Icon } from 'antd';

const { Option } = Select;

import intl from 'react-intl-universal';

import styles from './Index.less';

import TopBtn from '../../components/TopBtn';

@connect()
export default class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSync: false
    };
  }

  componentDidMount() {}

  setIsSync = status => {
    // 设置是否正在同步订单
    if (status) {
      this.setState({
        isSync: true,
        loopSync: true
      });
    } else {
      this.setState({
        isSync: false,
        loopSync: false
      });
    }
  };

  render() {
    const { isSync } = this.state;

    const cols = [];

    const listData = [
      {
        id: 1,
        title: intl.get('new_feature.csv_bulk_order'),
        describe: intl.get('new_feature.describe'),
        to: '/csv'
      }
    ];

    for (const item of listData.entries()) {
      cols.push(
        <Col key={item[1].id} span={8}>
          <Link className={styles.feature} to={item[1].to}>
            <h3>{item[1].title}</h3>
            <p>{item[1].describe}</p>
          </Link>
        </Col>
      );
    }

    return (
      <Fragment>
        {/* <div className={styles.top_but}>
          <TopBtn setIsSync={this.setIsSync} />
          <Select
            style={{ width: 180 }}
            placeholder="Select Store"
            disabled
            defaultValue="all"
          >
            <Option value="all" key="all">
              {intl.get('top.top_select_store')}
            </Option>
          </Select>
          {isSync ? (
            <Tooltip title="Synchronizing store..." placement="bottom">
              <Icon
                type="loading-3-quarters"
                spin
                style={{ fontSize: 20, color: '#fe8f24', marginLeft: 30 }}
              />
            </Tooltip>
          ) : null}
        </div> */}
        <div className={styles.box}>
          <Row gutter={40}>{cols}</Row>
        </div>
      </Fragment>
    );
  }
}
